import {Component} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';

export interface InfectionTransportation {
  code: string;
  fromTo: string;
  date: string;
  info: string;
}

const TRANSPORTATION_DATA: InfectionTransportation[] = [
  {code: '鑽石公主號', fromTo: '香港到日本橫濱', date: '25/01/2020-04/02/2020', info: '涉及3名香港人的日本個案'},
  {code: '港龍航空KA694', fromTo: '香港到海南省海口巿', date: '27/01/2020', info: '非本地個案'},
  {code: '國泰航空CX292', fromTo: '羅馬到香港', date: '31/01/2020', info: '非本地個案'},
  {code: '香港快運航空UO651', fromTo: '日本東京到香港', date: '01/02/2020', info: '第21,22個案'},
  {code: '國泰航空CX450', fromTo: '香港到台北', date: '01/02/2020', info: '非本地個案'},
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private http: HttpClient) {
    http
      .get('http://chp.gov.hk/files/misc/flights_trains_list_chi.csv', {responseType: 'text'})
      .subscribe((result: any) => {
        console.log('csv: ', result);
      });
  }

  title = 'phkl-pulse-outbreaks-flight';
  transportations = TRANSPORTATION_DATA;
}
